@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');

.Content {
    display: flex;
    height: 100vh;
    background: #333;
    line-height: 1.6;
    width: 100%;
    /* transition: width 1s ease; */
}

ul {
    list-style: none;
}

a {
    color: #333333;
    text-decoration: none;
}

h1, h2 {
    font-weight: 300;
    line-height: 1.2;
}

p {
    margin: 10px 0;
}

img {
    width: 100%;
}

/* Hero */
.hero {
    display: flex;
    flex-direction: column;
    background: url('../assets/images/doodle_lego.jpg') no-repeat center center/cover;
    height: 100vh;
    justify-content: center;
}

.hero .Title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero .Title h1 {
    font-size: 2.3rem;
}

.hero .Title h2 {
    font-size: 1.1rem;
    color: #00ddff;
}

/* Layout */
.Layout {
    display: flex;
    top: 0px;
}

/* Toolbar */
.Toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    position: absolute;
    opacity: 0.8;
    height: 70px;
    top: 0px;
    width: 100%;
}

.Toolbar a {
    color: #ff1d58;
    padding: 10px 20px;
    margin: 0 5px;
    font-weight: bolder;
}

.Toolbar p {
    color: #fff685;
    padding: 10px 20px;
    margin: 0 5px;
    font-weight: bolder;
    font-size: 30px;
}

.Toolbar a:hover {
    border-bottom: #ff1d58 2px solid;
}

.Toolbar ul {
    display: flex;
}

.Toolbar .Logo {
    padding: 8px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    align-items: center;
}

.Toolbar .Logo img{
    width: 8%;
}

.Toolbar.Close {
    width: 100%;
}

.Toolbar.Open {
    width: 85%;
    float: right;
}

/* HomePage */
.HomePageNumber{
    /* background: url('../assets/images/doodle_lego.jpg') no-repeat center center/cover; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    /* height: 100vh; */
    width: 100%;
}

.HomePage{
    font-family: 'Monoton';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* height: 100vh; */
    width: 100%;
}

.MeasurementNumber {
    font-size: 80px;
    color: #f75990;
}

.TextMeasurement {
    font-size: 50px;
    color: #ffffff;
}

.Copyright {
    font-size: 15px;
    color: #ffffff;
}

/* SignIn Page */
.AuthContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    background: url('../assets/images/doodle_astronauta.jpg') no-repeat center center/cover;
    width: 100%;
    height: 100vh;
}

.Authorization, h1 h2{
    font-size: 25px;
    color: #00ddff;
}

.NeedAcc, p {
    color: seashell;
}

.NeedAcc, a {
    font-size: 20px;
    color: #00ddff;
}

/* SignUp Page */
.SignUpContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    background: url('../assets/images/doodle_astronauta.jpg') no-repeat center center/cover;
    width: 100%;
    height: 100vh;
}

.SignUpContainer, h1 h2 {
    font-size: 25px;
    color: #00ddff;
}

/* Dashboard */
.Dashboard {
    background: url('../assets/images/doodle_lego.jpg') no-repeat center center/cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content:space-between; */
    text-align: center;
    height: 100vh;
    width: 100%;
}

.Dashboard, h1 {
    color: #00ddff;
}

.ContainerElements {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;    
}

.ContainerPlots {
    /* display: flex;    
    align-content: center;
    text-align: center; */
    /* background: rosybrown; */
    width: 100%;
    height: 100%;
}

.NoPlot h1 {
    color: chartreuse;
    
}

.DashboardElements {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content:space-between; */
    text-align: center;
    padding-top: 40px;
    width: 100%;          
}

.DashboardElement {
    padding: 15px; 
    background: #f75990;
    opacity: .85;
    border-radius: 10%;     
    margin-top: 25px;
    margin-left: 10px;
    margin-right: 10px;    
}

.DashboardElement h1{
    color: #00ddff;  
    font-size: 30px;  
}

.DashboardElement p{
    color: #fff685;  
    font-size: 30px;  
}

/* Device */
.DeviceContainer {
    background: url('../assets/images/doodle_lego.jpg') no-repeat center center/cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content:space-between; */
    text-align: center;
    height: 100vh;
    width: 100%;          
}

.DeviceContainer h1{ 
    color: #00ddff;  
    font-size: 30px; 
}

.DeviceElements {
    display: flex;
    flex-direction: row;
    align-items: center;                       
}

.DeviceElement {
    padding: 15px; 
    background: #fff685;
    opacity: .85;
    border-radius: 10%;     
    margin-top: 25px;
    margin-left: 10px;
    margin-right: 10px;    
}

.DeviceElement h1{
    color: #00ddff;  
    font-size: 30px; 
    align-content: center;
}

.DeviceElement p{
    color: #f75990;  
    font-size: 1.5em;  
    font-weight: bolder;
}

.DeviceButton {
    padding-top: 30px;
    color: red;
    border-bottom: #ff1d58 2px solid;
    list-style: none;    
}

/* Add Device */
.AddDeviceContainer{
    background: url('../assets/images/doodle_lego.jpg') no-repeat center center/cover;
    display: flex;
    flex-direction: column;
    align-items: center;    
    text-align: center;
    height: 100vh;
    width: 100%;       
}

.AddDeviceForm {
    margin-top: 70px;    
}

/* Footer */
.MainFooter {
    text-align: center;
    padding: 10px;
}

/* Documentation */
.Documentation {
    background: url('../assets/images/doodle_astronauta.jpg') no-repeat center center/cover;
    display: flex;
    height: 100%;  
    flex-direction: column;    
}

.Documentation .container {
    background: whitesmoke;
    color: black;
    padding: 20px;
    margin: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.Documentation h1{    
    align-self: center;
}

.Documentation .container p{    
    color: black;
}

.Documentation .container img.full{    
    width: 80%;
}

.Documentation .container img.half{    
    width: 20%;
}