/* Button */

.Button {
    cursor: pointer;
    display: inline-block;
    padding: 10px 30px;
    color: #000000;
    background-color: #28a745;
    border: none;
    border-radius: 5px;
}

.Button:hover {
    background-color: #fff685;
}

.Button.Success {
    background-color: transparent;
    color: #0049b7;
}

.Button.Danger {    
    color: #ff1d58;
    font-size: 1.2rem;
    font-weight: 450;
    outline: none;     
    border: 0px; 
    box-sizing: none; 
    background-color: transparent;     
}

.Button.Logout {
    background-color: #ff1d58;
}

.Button.Login {
    background-color: #a7d129;
    margin: 20px 20px;
}

.Button.Forgot {
    background: transparent;
    /* background-color: #ff1d58; */
    margin: 20px 20px;
    color: red;
}

.Button.Element {
    background-color: #00ddff;
    margin: 20px 20px;
    color: #ff1d58;
}

.Button.Signup {
    background-color: #fff685;
}

.Button.LogOut:hover, 
.Button.Danger:hover, 
.Button.Success:hover,
.Button.Element:hover {
    background-color: #fff685;
}

/* Input */
.Input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    font-size: 20px;
}

.Label {
    font-weight: bolder;
    margin-right: 15px;
    margin-bottom: 8px;
    color: seashell;
}

.InputElement {
    outline: none;
    border: 1px solid #cccccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;    
}

.InputElement:focus {
    outline: none;
    background-color: #cccccc;
}

.Invalid {
    border: ipx solid red;
    background-color: #fda9af;
}

.ValidationError {
    color:red;
    margin: 5px 0;
}

.CInvalid {
    color: #f75990;
}

/* Modals */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modalmain {
  position:fixed;
  background: black;
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  font-size: larger;
  color: #0049b7;
}

.displayblock {
  display: block;
}

.displaynone {
  display: none;
}