@import url(https://fonts.googleapis.com/css2?family=Monoton&display=swap);
body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'IBM Plex Mono', monospace;
  background-color: #333;
}
.style__Content__1s-3o {
    display: flex;
    height: 100vh;
    background: #333;
    line-height: 1.6;
    width: 100%;
    /* transition: width 1s ease; */
}

ul {
    list-style: none;
}

a {
    color: #333333;
    text-decoration: none;
}

h1, h2 {
    font-weight: 300;
    line-height: 1.2;
}

p {
    margin: 10px 0;
}

img {
    width: 100%;
}

/* Hero */
.style__hero__1JDLy {
    display: flex;
    flex-direction: column;
    background: url(/static/media/doodle_lego.07a1e453.jpg) no-repeat center center/cover;
    height: 100vh;
    justify-content: center;
}

.style__hero__1JDLy .style__Title__3mWui {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.style__hero__1JDLy .style__Title__3mWui h1 {
    font-size: 2.3rem;
}

.style__hero__1JDLy .style__Title__3mWui h2 {
    font-size: 1.1rem;
    color: #00ddff;
}

/* Layout */
.style__Layout__1v2YV {
    display: flex;
    top: 0px;
}

/* Toolbar */
.style__Toolbar__13Ytq {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    position: absolute;
    opacity: 0.8;
    height: 70px;
    top: 0px;
    width: 100%;
}

.style__Toolbar__13Ytq a {
    color: #ff1d58;
    padding: 10px 20px;
    margin: 0 5px;
    font-weight: bolder;
}

.style__Toolbar__13Ytq p {
    color: #fff685;
    padding: 10px 20px;
    margin: 0 5px;
    font-weight: bolder;
    font-size: 30px;
}

.style__Toolbar__13Ytq a:hover {
    border-bottom: #ff1d58 2px solid;
}

.style__Toolbar__13Ytq ul {
    display: flex;
}

.style__Toolbar__13Ytq .style__Logo__1FVDk {
    padding: 8px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    align-items: center;
}

.style__Toolbar__13Ytq .style__Logo__1FVDk img{
    width: 8%;
}

.style__Toolbar__13Ytq.style__Close__1ch1h {
    width: 100%;
}

.style__Toolbar__13Ytq.style__Open__sFwX_ {
    width: 85%;
    float: right;
}

/* HomePage */
.style__HomePageNumber__1Pakh{
    /* background: url('../assets/images/doodle_lego.jpg') no-repeat center center/cover; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    /* height: 100vh; */
    width: 100%;
}

.style__HomePage__39D3E{
    font-family: 'Monoton';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* height: 100vh; */
    width: 100%;
}

.style__MeasurementNumber__3wWHb {
    font-size: 80px;
    color: #f75990;
}

.style__TextMeasurement__1r9bp {
    font-size: 50px;
    color: #ffffff;
}

.style__Copyright__2mdra {
    font-size: 15px;
    color: #ffffff;
}

/* SignIn Page */
.style__AuthContainer__22-Gn{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    background: url(/static/media/doodle_astronauta.7e0403b7.jpg) no-repeat center center/cover;
    width: 100%;
    height: 100vh;
}

.style__Authorization__3-YX1, h1 h2{
    font-size: 25px;
    color: #00ddff;
}

.style__NeedAcc__3ePzr, p {
    color: seashell;
}

.style__NeedAcc__3ePzr, a {
    font-size: 20px;
    color: #00ddff;
}

/* SignUp Page */
.style__SignUpContainer__2YYkT {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    background: url(/static/media/doodle_astronauta.7e0403b7.jpg) no-repeat center center/cover;
    width: 100%;
    height: 100vh;
}

.style__SignUpContainer__2YYkT, h1 h2 {
    font-size: 25px;
    color: #00ddff;
}

/* Dashboard */
.style__Dashboard__3MWZM {
    background: url(/static/media/doodle_lego.07a1e453.jpg) no-repeat center center/cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content:space-between; */
    text-align: center;
    height: 100vh;
    width: 100%;
}

.style__Dashboard__3MWZM, h1 {
    color: #00ddff;
}

.style__ContainerElements__1fNTm {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;    
}

.style__ContainerPlots__3zF7J {
    /* display: flex;    
    align-content: center;
    text-align: center; */
    /* background: rosybrown; */
    width: 100%;
    height: 100%;
}

.style__NoPlot__3DwDW h1 {
    color: chartreuse;
    
}

.style__DashboardElements__at8M1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content:space-between; */
    text-align: center;
    padding-top: 40px;
    width: 100%;          
}

.style__DashboardElement__31dIE {
    padding: 15px; 
    background: #f75990;
    opacity: .85;
    border-radius: 10%;     
    margin-top: 25px;
    margin-left: 10px;
    margin-right: 10px;    
}

.style__DashboardElement__31dIE h1{
    color: #00ddff;  
    font-size: 30px;  
}

.style__DashboardElement__31dIE p{
    color: #fff685;  
    font-size: 30px;  
}

/* Device */
.style__DeviceContainer__nWyRB {
    background: url(/static/media/doodle_lego.07a1e453.jpg) no-repeat center center/cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content:space-between; */
    text-align: center;
    height: 100vh;
    width: 100%;          
}

.style__DeviceContainer__nWyRB h1{ 
    color: #00ddff;  
    font-size: 30px; 
}

.style__DeviceElements__1dCVI {
    display: flex;
    flex-direction: row;
    align-items: center;                       
}

.style__DeviceElement__lC6GT {
    padding: 15px; 
    background: #fff685;
    opacity: .85;
    border-radius: 10%;     
    margin-top: 25px;
    margin-left: 10px;
    margin-right: 10px;    
}

.style__DeviceElement__lC6GT h1{
    color: #00ddff;  
    font-size: 30px; 
    align-content: center;
}

.style__DeviceElement__lC6GT p{
    color: #f75990;  
    font-size: 1.5em;  
    font-weight: bolder;
}

.style__DeviceButton__18a0C {
    padding-top: 30px;
    color: red;
    border-bottom: #ff1d58 2px solid;
    list-style: none;    
}

/* Add Device */
.style__AddDeviceContainer__1vETp{
    background: url(/static/media/doodle_lego.07a1e453.jpg) no-repeat center center/cover;
    display: flex;
    flex-direction: column;
    align-items: center;    
    text-align: center;
    height: 100vh;
    width: 100%;       
}

.style__AddDeviceForm__3svE1 {
    margin-top: 70px;    
}

/* Footer */
.style__MainFooter__2QDbN {
    text-align: center;
    padding: 10px;
}

/* Documentation */
.style__Documentation__46f_T {
    background: url(/static/media/doodle_astronauta.7e0403b7.jpg) no-repeat center center/cover;
    display: flex;
    height: 100%;  
    flex-direction: column;    
}

.style__Documentation__46f_T .style__container__n_Zh1 {
    background: whitesmoke;
    color: black;
    padding: 20px;
    margin: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.style__Documentation__46f_T h1{    
    align-self: center;
}

.style__Documentation__46f_T .style__container__n_Zh1 p{    
    color: black;
}

.style__Documentation__46f_T .style__container__n_Zh1 img.style__full__17auG{    
    width: 80%;
}

.style__Documentation__46f_T .style__container__n_Zh1 img.style__half__2SgG0{    
    width: 20%;
}
/* Button */

.utilities__Button__daEhN {
    cursor: pointer;
    display: inline-block;
    padding: 10px 30px;
    color: #000000;
    background-color: #28a745;
    border: none;
    border-radius: 5px;
}

.utilities__Button__daEhN:hover {
    background-color: #fff685;
}

.utilities__Button__daEhN.utilities__Success__3FE6R {
    background-color: transparent;
    color: #0049b7;
}

.utilities__Button__daEhN.utilities__Danger__15m_W {    
    color: #ff1d58;
    font-size: 1.2rem;
    font-weight: 450;
    outline: none;     
    border: 0px; 
    box-sizing: none; 
    background-color: transparent;     
}

.utilities__Button__daEhN.utilities__Logout__1AhXg {
    background-color: #ff1d58;
}

.utilities__Button__daEhN.utilities__Login__hrnOs {
    background-color: #a7d129;
    margin: 20px 20px;
}

.utilities__Button__daEhN.utilities__Forgot__3HRdt {
    background: transparent;
    /* background-color: #ff1d58; */
    margin: 20px 20px;
    color: red;
}

.utilities__Button__daEhN.utilities__Element__2tCdx {
    background-color: #00ddff;
    margin: 20px 20px;
    color: #ff1d58;
}

.utilities__Button__daEhN.utilities__Signup__22t8i {
    background-color: #fff685;
}

.utilities__Button__daEhN.utilities__LogOut__2XSx6:hover, 
.utilities__Button__daEhN.utilities__Danger__15m_W:hover, 
.utilities__Button__daEhN.utilities__Success__3FE6R:hover,
.utilities__Button__daEhN.utilities__Element__2tCdx:hover {
    background-color: #fff685;
}

/* Input */
.utilities__Input__2H-wk {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    font-size: 20px;
}

.utilities__Label__2m9dI {
    font-weight: bolder;
    margin-right: 15px;
    margin-bottom: 8px;
    color: seashell;
}

.utilities__InputElement__2H7Q- {
    outline: none;
    border: 1px solid #cccccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;    
}

.utilities__InputElement__2H7Q-:focus {
    outline: none;
    background-color: #cccccc;
}

.utilities__Invalid__1BdNA {
    border: ipx solid red;
    background-color: #fda9af;
}

.utilities__ValidationError__1djbK {
    color:red;
    margin: 5px 0;
}

.utilities__CInvalid__sP0pL {
    color: #f75990;
}

/* Modals */
.utilities__modal__2qC08 {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.utilities__modalmain__7mXSE {
  position:fixed;
  background: black;
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  font-size: larger;
  color: #0049b7;
}

.utilities__displayblock__9sWBf {
  display: block;
}

.utilities__displaynone__2H_y- {
  display: none;
}
.Spinner__loader__90oc-,
.Spinner__loader__90oc-:before,
.Spinner__loader__90oc-:after {
  background: #ffffff;
  -webkit-animation: Spinner__load1__HI2PP 1s infinite ease-in-out;
  animation: Spinner__load1__HI2PP 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.Spinner__loader__90oc- {
  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.Spinner__loader__90oc-:before,
.Spinner__loader__90oc-:after {
  position: absolute;
  top: 0;
  content: '';
}
.Spinner__loader__90oc-:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.Spinner__loader__90oc-:after {
  left: 1.5em;
}
@-webkit-keyframes Spinner__load1__HI2PP {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes Spinner__load1__HI2PP {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

